import IHeader from "@component/IHeader/index.vue";
import IFooter from "@component/IFooter/index.vue";
import {
  NUM_REG,
  PWD_REG,
  //  NAME_REG,
  // CODE_REG,
  REMARK_REG,
} from "@/utils/reg";
import {
  userRegister,
  sendCode,
  checkVerifyCode,
  ImgUploader,
} from "@lib/index";
export default {
  data() {
    // 验证手机号
    const checkAcc = (rules, value, callback) => {
      if (!value) {
        callback(new Error("请输入手机号码"));
        this.remarkTrue = true;
      } else if (!NUM_REG.test(value)) {
        callback(new Error("手机号码格式错误!"));
        this.remarkTrue = true;
      } else {
        callback();
        if (!this.timer) {
          this.remarkTrue = false;
        }
      }
    };
    // 验证密码
    const checkPwd = (rules, value, callback) => {
      if (!value) {
        callback(new Error("请输入密码"));
      } else if (!PWD_REG.test(value)) {
        callback(new Error("请输入6-12位字符包括字母、数字及下划线"));
      } else {
        callback();
      }
    };
    //   再次确认密码
    const checkPwdAgain = (rules, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.registerForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    //   短信验证码
    const checkRemark = (rules, value, callback) => {
      if (value === "") {
        callback(new Error("请输入短信验证码"));
      } else if (!REMARK_REG.test(value)) {
        callback(new Error("短信验证码格式错误!"));
      } else if (value !== this.registerForm.remark) {
        callback(new Error("短信验证码错误!"));
      } else {
        callback();
      }
    };
    //   企业全称验证
    const checkCompanies = (rules, value, callback) => {
      if (!value) {
        callback(new Error("请输入企业全称"));
      } else {
        callback();
      }
    };
    //   联系电话验证
    const checkTNum = (rules, value, callback) => {
      if (!value) {
        callback(new Error("请输入联系电话"));
      } else if (!NUM_REG.test(value)) {
        callback(new Error("联系电话格式错误"));
      } else {
        callback();
      }
    };
    //   企业地址验证
    const checkAddress = (rules, value, callback) => {
      if (!value) {
        callback(new Error("请输入企业地址"));
      } else {
        callback();
      }
    };
    //   企业法人验证
    const checkCorporation = (rules, value, callback) => {
      if (!value) {
        callback(new Error("请输入企业法人"));
      } else {
        callback();
      }
    };
    // 统一信用代码验证
    const checkCoded = (rules, value, callback) => {
      if (!value) {
        callback(new Error("请输入统一信用代码信息"));
      } else if (!/^[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/.test(value)) {
        callback(new Error("统一信用代码格式错误"));
      } else {
        callback();
      }
    };
    // 营业执照验证
    const checkLicense = (rules, value, callback) => {
      if (this.accountForm.imageUrl == "") {
        callback(new Error("请上传营业执照"));
      } else {
        callback();
      }
    };
    return {
      current: 1,
      remarkTrue: true,
      // 注册表单信息
      registerForm: {
        categoryId: "developer",
        telphone: "",
        remark: "",
        password: "",
        checkpassword: "",
      },
      registerRules: {
        telphone: { required: true, validator: checkAcc, trigger: "blur" },
        password: { required: true, validator: checkPwd, trigger: "blur" },
        checkpassword: {
          required: true,
          validator: checkPwdAgain,
          trigger: "blur",
        },
        remark: { required: true, validator: checkRemark, trigger: "blur" },
      },
      // 账号表单信息
      accountForm: {
        companies: "",
        creditCoded: "",
        address: "",
        corporation: "",
        telphone: "",
      },
      accountRules: {
        companies: { validator: checkCompanies, trigger: "blur" },
        creditCoded: { validator: checkCoded, trigger: "blur" },
        address: { validator: checkAddress, trigger: "blur" },
        corporation: { validator: checkCorporation, trigger: "fous" },
        telphone: {
          validator: checkTNum,
          trigger: "blur",
        },
        license: { validator: checkLicense, trigger: "blur" },
      },
      setCode: "获取验证码",
      yzmType: 2,
      //   账号信息
      baseUrl: "http://127.0.0.1:5000/upload/shop/",
      // 营业执照
      imageUrl: "",
      resID: "",
      File: "",
    };
  },
  methods: {
    // 点击去登录
    goLogin() {
      this.$router.push("/login");
    },
    // 下一步
    next() {
      if (this.current < 4) {
        this.current++;
      }
    },
    // 获取短信验证码
    async getYzm() {
      let { status, result } = await sendCode(this.registerForm.telphone);
      if (status === "error") {
        this.$message.error(result?.error_msg || "验证码发送失败");
      } else {
        this.validateBtn();
        this.$message.success("验证码发送成功");
      }
    },
    validateBtn() {
      //倒计时
      let time = 60;
      this.timer = setInterval(() => {
        if (time == 0) {
          clearInterval(this.timer);
          this.timer = null;
          this.remarkTrue = false;
          this.setCode = "重新获取验证码";
        } else {
          this.setCode = time + "秒后重试";
          this.remarkTrue = true;
          time--;
        }
      }, 1000);
    },
    // 确认注册表单信息
    submitRegisterForm(registerForm) {
      this.$refs[registerForm].validate(async (valid) => {
        if (valid) {
          let res = await checkVerifyCode({
            code: this.registerForm.remark,
            phone: this.registerForm.telphone,
          });
          if (res.result.code === 200) {
            if (this.current < 4) {
              this.current++;
            }
          } else {
            this.$message.error(res.result.message);
          }
        } else {
          return false;
        }
      });
    },
    //   营业执照
    handleAvatarSuccess(val) {
      let { code, imgUrl } = val;
      if (code == 0) {
        this.imageUrl = imgUrl;
      }
    },
    // 营业执照上传成功之前
    beforeAvatarUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;
      const isIMG = file.type === "image/jpeg" || file.type === "image/png";

      if (!isIMG) {
        this.$message.error("上传头像图片只能是 JPG、PNG 格式!");
      }

      if (!isLt1M) {
        this.$message.error("上传头像图片大小不能超过 1MB!");
      }
      return isLt1M && isIMG;
    },
    //   确认账号表单信息
    submitAccountForm(accountForm) {
      this.$refs[accountForm].validate(async (valid) => {
        if (valid) {
          if (!this.File) {
            this.$message.error("请上传营业执照");
            return;
          }
          let res = await userRegister({
            loginName: this.registerForm.telphone,
            categoryId: this.registerForm.categoryId,
            name: "",
            orgAddress: this.accountForm.address,
            orgCode: this.accountForm.creditCoded,
            orgLegalPerson: this.accountForm.corporation,
            orgName: this.accountForm.companies,
            orgPhone: this.accountForm.telphone,
            password: this.registerForm.password,
            phone: "",
          });
          if (res.status === "success") {
            this.current = 4;
            this.resID = res.result.id;
            let formD = new FormData();
            formD.append("file", this.File);

            ImgUploader(formD, this.resID).then(() => {});
          } else {
            this.$message.error(res.result.error_msg);
            this.current = 1;
          }
        } else {
          return false;
        }
      });
    },

    goodNewPicFn(val) {
      this.File = val.file;
    },
    handleExceed() {
      this.$message.warning(`营业执照只能上传一张图片`);
    },
  },
  components: {
    [IHeader.name]: IHeader,
    [IFooter.name]: IFooter,
  },
  mounted() {},
};
